import { defineStore } from 'pinia'

export const useDownloadApp = () => {
  return defineStore('downloadApp', {
    state: (): {
      openedDownloadDialougeBefore: boolean
    } => ({
      openedDownloadDialougeBefore: false
    }),

    actions: {
      updateOpenedDownloadDialouge(value: boolean) {
        this.openedDownloadDialougeBefore = value
      }
    },

    persist: [{ pick: ['openedDownloadDialougeBefore'], storage: persistedState.cookies }]
  })()
}
