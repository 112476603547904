<template>
  <div class="text-center py-3 text-text_alt_color">
    {{ t('got_a_question') }}
  </div>
  <div
    class="flex flex-wrap items-center justify-center text-text_alt_color sm:pb-3 mx-auto"
  >
    <div v-if="settings?.email" class="style-icon">
      <a :href="`mailto:${settings?.email}`">
        <v-icon icon="mdi-email" class="mx-auto custom-font-size" />
      </a>
      <div class="style-icon-down"></div>
    </div>

    <div v-if="settings?.phone" class="style-icon">
      <a :href="`tel:${settings?.phone}`">
        <v-icon icon="mdi-phone" class="mx-auto custom-font-size" />
      </a>
      <div class="style-icon-down"></div>
    </div>
    <div v-if="settings?.twitter?.trim()" class="style-icon">
      <a :href="settings?.twitter" target="_blank">
        <v-icon
          icon="fa: fa-brands fa-twitter"
          class="mx-auto custom-font-size"
        />

      </a>
      <div
        class="style-icon-down"
      ></div>
    </div>
    <div v-if="settings?.facebook?.trim()" class="style-icon">
      <a :href="settings?.facebook" target="_blank">
        <v-icon
          icon="fa: fa-brands fa-facebook "
          class="custom-font-size mx-auto"
        />
      </a>
      <div class="style-icon-down"></div>
    </div>
    <div v-if="settings?.whatsapp?.trim()" class="style-icon">
      <v-icon
        icon="mdi mdi-whatsapp"
        class="custom-font-size mx-auto"
        @click="openWhatsapp()"
      />
      <div class="style-icon-down"></div>
    </div>
    <div v-if="settings?.tiktok?.trim()" class="style-icon">
      <a :href="settings?.tiktok" target="_blank">
        <v-icon
          icon="fa: fa-brands fa-tiktok"
          class="custom-font-size mx-auto"
        />
      </a>
      <div class="style-icon-down"></div>
    </div>
    <div v-if="settings?.instagram?.trim()" class="style-icon">
      <a :href="settings?.instagram" target="_blank">
        <v-icon
          icon="fa: fa-brands fa-instagram"
          class="custom-font-size mx-auto"
        />
      </a>
      <div class="style-icon-down"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { settings } = useSettings()
const { t } = useI18n()
function openWhatsapp() {
  window.open(
    'https://api.whatsapp.com/send?phone=' + settings.value.whatsapp + '&text=' + t('hello'),
    '_blank'
  )
}
</script>

<style>
.style-icon {
  @apply mx-1 border-double relative text-nav_color px-0.5 sm:p-1 bg-bg_color rounded-full border-4 border-nav_color;
}
.style-icon-down {
  @apply absolute -bottom-5 w-3 h-6 bg-bg_color left-1/2 -translate-x-1/2;
}
.v-icon--size-default.custom-font-size {
  @apply mb-1 text-[16px] sm:text-[24px] !important;
}
</style>
