<template>
  <div>
    <div v-if="!isMobileOrTablet">
      <LayoutFooterStore />
      <img
        v-if="!routeIsForStorePage"
        width="256"
        height="241"
        src="/images/mevcut.webp"
        class="mx-auto"
        :alt="t('mevcut')"
      />
      <div class="bg-nav_color">
        <LayoutFooterActions />
      </div>
    </div>
    <div class="h-5"></div>
  </div>
</template>

<script setup lang="ts">
const { isMobileOrTablet } = useDevice()
const { t } = useI18n()

const routeIsForStorePage = computed(() => {
  return /\/stores\/./.test(useRoute().path)
})
</script>
