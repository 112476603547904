<template>
  <div
    v-if="settings?.google_play || settings?.apple_store"
    class="p-5 xs:py-10 flex flex-col items-center justify-center"
  >
    <div class="text-center text-3xl font-medium">
      {{ t('download_app_title') }}
    </div>
    <div class="text-center text-xl text-mevcut-light">
      {{ t('download_app_subtitle') }}
    </div>

    <!-- Store's Badges -->
    <div class="flex flex-col xs:flex-row items-center justify-center mt-7">
      <a
        v-if="settings?.google_play"
        :href="settings?.google_play"
        target="_blank"
        class="m-2 transform transition-all duration-500 hover:scale-105"
      >
        <img
          src="/images/play_store_badge.webp"
          alt="Play store"
          width="192"
          height="55"
        />
      </a>
      <div
        v-if="settings?.apple_store"
        :href="settings?.apple_store"
        target="_blank"
        class="m-2 transform transition-all duration-500 hover:scale-105"
      >
        <img
          src="/images/app_store_badge.webp"
          alt="Apple store"
          width="192"
          height="55"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { settings } = useSettings()
const { t } = useI18n()
</script>
