<template>
  <div v-if="deviceIsAndriod || deviceIsIos">
    <MevcutDialouge
      :model-value="isOpenedBigDialouge"
      :with-vmodel="true"
      width="auto"
      @update:model-value="showSmallDialouge($event)"
    >
      <template #content>
        <div class="p-3 mevcut-container w-full max-h-[85vh]">
          <div class="font-semibold">
            <div class="text-center text-black">
              {{ t('try_app_message') }}
            </div>
            <LayoutDownloadAppIconsBySystem
              class="mt-2"
              :device-is-andriod="deviceIsAndriod"
              :device-is-ios="deviceIsIos"
            />
          </div>
        </div>
      </template>
    </MevcutDialouge>

    <div
      v-if="isOpenedSmallDialouge"
      class="py-1 text-sm   bg-text_color text-bg_color fixed bottom-0 z-50 w-full"
    >
      <div class="flex justify-center mb-2">
        <div class="flex-auto"></div>
        <LayoutDownloadAppIconsBySystem

          style-download-directly="bg-bg_color text-text_color"
          :device-is-andriod="deviceIsAndriod"
          :device-is-ios="deviceIsIos"
        />
        <div class="flex-auto"></div>
        <v-icon
          icon="mdi mdi-close-box"
          :size="24"
          class="cursor-pointer px-2"
          @click="isOpenedSmallDialouge = false"
        />
      </div>
      <div class="text-center">
        {{ t('try_app_message') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { openedDownloadDialougeBefore, updateOpenedDownloadDialouge } =
  useDownloadApp()
let intervalId: ReturnType<typeof setInterval> | null = null
const isOpenedSmallDialouge = ref(openedDownloadDialougeBefore)
const isOpenedBigDialouge = ref(!openedDownloadDialougeBefore)
const deviceIsAndriod = ref(false)
const deviceIsIos = ref(false)
onMounted(() => {
  deviceIsAndriod.value = /Android/i.test(navigator.userAgent)
  deviceIsIos.value = /iPhone|iPad|iPod/i.test(navigator.userAgent)
  if (openedDownloadDialougeBefore && !intervalId) {
    intervalId = setInterval(() => {
      isOpenedSmallDialouge.value = true
    }, 60000)
  }
})

function showSmallDialouge(valueBigDialougeIsOpened: boolean) {
  isOpenedBigDialouge.value = valueBigDialougeIsOpened

  if (!valueBigDialougeIsOpened) {
    updateOpenedDownloadDialouge(true)
    // بدء الفتح المتكرر للديالوج الصغير
    if (!intervalId) {
      intervalId = setInterval(() => {
        isOpenedSmallDialouge.value = true
      }, 60000) // الانتظار لمدة دقيقة (60000 مللي ثانية)
    }
  } else {
    isOpenedSmallDialouge.value = false
    // إيقاف التكرار عند إعادة فتح الديالوج الكبير
    if (intervalId) {
      clearInterval(intervalId)
      intervalId = null
    }
  }
}
</script>
