<template>
  <div :data-theme="routeTheme">
    <Head>
      <Style
        type="text/css"
        :children="`*[data-theme='shopping'] { ${shoppingTheme.bgColor} ${shoppingTheme.bgAltColor} ${shoppingTheme.textColor} ${shoppingTheme.navColor} ${shoppingTheme.buttonColor} ${shoppingTheme.textAltColor}}`"
      ></Style>

      <Style
        type="text/css"
        :children="`*[data-theme='delivering'] { ${deliveringTheme.bgColor} ${deliveringTheme.bgAltColor} ${deliveringTheme.textColor} ${deliveringTheme.navColor} ${deliveringTheme.buttonColor} ${deliveringTheme.textAltColor}}`"
      ></Style>
    </Head>

    <NuxtLoadingIndicator
      color="repeating-linear-gradient(to right,#5A3CB8 0%,#F76319 100%)"
    />

    <Head>
      <Link rel="icon" type="image/x-icon" href="/favicon.ico" />
    </Head>

    <slot />
  </div>
</template>

<script setup lang="ts">
const route = useRoute()

const routeTheme = computed(() => {
  return route.path.startsWith('/delivering') ? 'delivering' : 'shopping'
})

const shoppingTheme = {
  bgColor: useGetRGBColor('#FFF7FA', 'bg-color'),
  bgAltColor: useGetRGBColor('#EFDEEA', 'bg-alt-color'),
  textColor: useGetRGBColor('#5A3CB8', 'text-color'), // used with bg, bg_alt colors
  navColor: useGetRGBColor('#5A3CB8', 'nav-color'),
  buttonColor: useGetRGBColor('#4321A8', 'button-color'),
  textAltColor: useGetRGBColor('#ffffff', 'text-alt-color') // used with nav, button color
}

const deliveringTheme = {
  bgColor: useGetRGBColor('#FFF8F6', 'bg-color'),
  bgAltColor: useGetRGBColor('#EDD5CD', 'bg-alt-color'),
  textColor: useGetRGBColor('#F76319', 'text-color'), // used with bg, bg_alt colors
  navColor: useGetRGBColor('#F76319', 'nav-color'),
  buttonColor: useGetRGBColor('#ED560A', 'button-color'),
  textAltColor: useGetRGBColor('#ffffff', 'text-alt-color') // used with nav, button color
}
</script>
