<template>
  <div class="text-text_color bg-bg_color sm:py-5">
    <div class="mevcut-container flex items-center">
      <!-- Logo -->
      <div class="mx-3">
        <MevcutLink to="/" :aria-label="t('home')">
          <img
            src="/images/logo.webp"
            width="75"
            height="49"
            class="w-10 sm:w-16 mx-auto"
            :alt="t('mevcut')"
          />
        </MevcutLink>
      </div>
      <div class="flex mx-2">
        <div
          v-for="(link, index) in menuLinks"
          :key="`navbar-menu-item-${index}`"
        >
          <MevcutLink
            :to="link.route"
            class="navbar-link"
            :aria-label="link.title"
          >
            <span>{{ link.title }} </span>
            <span class="dot-link"> </span>
          </MevcutLink>
        </div>
      </div>

      <!-- Separator -->
      <div class="flex-auto"></div>

      <!-- Search -->
      <LayoutNavbarSearchField v-if="!isDelivering" class="w-52 mx-3 hidden md:block" />

      <!-- Actions -->
      <LayoutNavbarPlatformDesktopActions />

      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const isDelivering = useIsDelivering()
const menuLinks = [
  { title: t('shopping'), route: '/' },
  { title: t('at_yours'), route: '/delivering' },
  { title: t('offers_discounts'), route: '/offers' }
]
</script>
<style scoped>
.navbar-link {
  @apply mx-1 px-4 font-medium text-lg text-center transition-all duration-300 relative hover:text-nav_color;
}

.navbar-link.router-link-exact-active {
  @apply text-nav_color;
}

.navbar-link > .dot-link {
  @apply absolute -bottom-1 left-1/2 w-5 h-0.5 rounded-full bg-transparent transition-all duration-500 transform -translate-x-1/2;
}

.navbar-link.router-link-exact-active > .dot-link {
  @apply bg-nav_color;
}
</style>
