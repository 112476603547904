<template>
  <ProvidersPlatform>
    <v-app class="custom-bg">
      <v-locale-provider :rtl="useIsRtl()">
        <div class="sticky top-0 z-30">
          <LayoutNavbarPlatformDesktopNavbar
            v-if="!isMobileOrTablet"
            class="w-full"
          />
          <DevHint v-if="devHint" />
        </div>

        <main>
          <slot />
        </main>

        <footer>
          <LayoutBottomNavigationPlatform
            v-if="isMobileOrTablet && !routeIsForMaterialPage()"
            class="fixed w-full bottom-0 z-40 bg-white"
          />

          <ClientOnly>
            <LayoutFooter class="mb-20" />
          </ClientOnly>
        </footer>
        <LayoutDownloadApp />
      </v-locale-provider>
    </v-app>
  </ProvidersPlatform>
</template>

<script setup lang="ts">
const { url } = useDomainHost()
const { routeIsForMaterialPage } = useMenuModel()

const { isMobileOrTablet } = useDevice()
const devHint = ref(process.env.NUXT_PUBLIC_PLATFORM === 'dev.mevcut.co')

const { initialize } = useGtag()

onMounted(() => {
  initialize()
})

useHead({
  script: [useSeoLogo(url, url + '/images/mevcut.webp')]
})
</script>

<style scoped>
.custom-bg {
  @apply bg-bg_color text-text_color !important;
}
</style>
