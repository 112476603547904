<template>
  <ThemeHeadPlatForm>
    <slot />
  </ThemeHeadPlatForm>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import { usePlacesStore } from '~/store/places'
const { storeTypeModel, cityModel } = useMenuModel()
const { setNewOffersNumber } = useOffersNumber()
const { loadAnnouncements } = useAnnouncementStore()
const { setStoreTypes } = useStoreTypes()
const { fetchCities, fetchAgentPlace } = usePlacesStore()
const { getProfile } = useProfile()

const allRoutes = getAllRoutes()

async function fetchStoreTypes() {
  await useBasicFetch(allRoutes.storeTypeRoutes.getStoresType, {
    query: { limit: 100, with_category: true, with_material_definitions: 1 },
    transform: (data: SuccessResponse<StoreType[]>) => {
      const dataTemp = data.data.map(storeTypeModel)
      setStoreTypes(dataTemp ?? [])
      return dataTemp
    }
  })
}
async function fetchOffersNumbers() {
  await useBasicFetch(allRoutes.materialsRoutes.materialsByServer, {
    query: { offersOnly: true },
    transform: (data: SuccessResponse<Material[]>) => {
      setNewOffersNumber(data.data.length)
      return data.data
    }
  })
}

await Promise.all([
  fetchCities(cityModel),
  getProfile(),
  fetchStoreTypes(),
  fetchAgentPlace(),
  fetchOffersNumbers(),
  loadAnnouncements()
])
</script>
